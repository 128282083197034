import * as type from 'constants/action_types/review_automation'
import { t } from 'services/i18n'
import {
  REVIEW_AUTOMATION_ACTIVATED,
  REVIEW_REQUEST_DELAY_UPDATED
} from 'constants/activation_points/review_automation'
import {
  SET_AMAZON_SELLER_ACCOUNT_SELECTOR_DATA,
  SET_AMAZON_SELLER_ACCOUNTS_DATA
} from 'constants/action_types/amazon_seller_accounts'
import Api from 'services/api/review_automation'
import { sendSegmentTrackEvent, safeSegmentCall } from 'services/segment'
import { getSelectedAmazonSellerAccount } from 'helpers/amazon_seller_account'
import { stripURLParams } from 'helpers/url'
import { setGlobalModal } from 'actions/index'
import { SKIPPED_ENABLE_ALL_MARKETPLACES_KEY } from 'constants/local_storage'
import { createNotification } from './notifications'

export const toggleReviewAutomation = reviewAutomationEnabled => async (
  dispatch,
  getState
) => {
  const state = getState()
  const {
    selectedAccount,
    selectedCountry,
    amazon_seller_account_id,
    marketplace_id,
    country_code
  } = getSelectedAmazonSellerAccount(state)

  const countries = selectedAccount?.countries || []

  const {
    amazonSellerAccounts: { amazonSellerAccountSelector }
  } = state

  const data = {
    amazon_seller_account_id,
    review_automation_enabled: reviewAutomationEnabled,
    amazon_marketplace: country_code
  }

  dispatch({
    type: type.PENDING_REVIEW_AUTOMATION_ACTION,
    payload: 'toggle'
  })

  const res = await Api.updateMarketplace(marketplace_id, data)

  dispatch({
    type: type.COMPLETED_REVIEW_AUTOMATION_ACTION,
    payload: 'toggle'
  })

  if (res.ok) {
    const updatedCountryIndex = countries?.findIndex(
      country => country.marketplace_id === marketplace_id
    )
    if (updatedCountryIndex && updatedCountryIndex !== -1) {
      countries[
        updatedCountryIndex
      ].review_automation_enabled = reviewAutomationEnabled
    }
    dispatch({
      type: SET_AMAZON_SELLER_ACCOUNT_SELECTOR_DATA,
      payload: {
        ...amazonSellerAccountSelector,
        selectedAccount: {
          ...selectedAccount,
          countries
        },
        selectedCountry: {
          ...selectedCountry,
          review_automation_enabled: reviewAutomationEnabled
        }
      }
    })
    if (reviewAutomationEnabled) {
      safeSegmentCall(() => {
        sendSegmentTrackEvent(REVIEW_AUTOMATION_ACTIVATED, {
          pageURL: stripURLParams(window.location.href)
        })
      })
    }
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Toggle Review Automation Error'
      })
    )
  }
}

export const promptToEnableAllMarketplaces = (isHeaderToggle = false) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const { selectedAccount, selectedCountry } = getSelectedAmazonSellerAccount(
    state
  )

  const reviewAutomationEnabled = selectedCountry?.review_automation_enabled

  const amazonSellerAccountCountries = selectedAccount?.countries

  const raEnabledForAllAccounts = amazonSellerAccountCountries?.every(
    account => {
      if (!account.country_code) {
        return true
      }

      return account.review_automation_enabled
    }
  )

  if (raEnabledForAllAccounts) {
    if (isHeaderToggle) {
      dispatch(toggleReviewAutomation(!reviewAutomationEnabled))
    }
    return
  }

  if (isHeaderToggle && reviewAutomationEnabled) {
    dispatch(toggleReviewAutomation(!reviewAutomationEnabled))
    return
  }

  // If not all of the RA accounts are enabled, prompt user to enable all
  const skippedEnableAllMarketplacesDate = localStorage.getItem(
    SKIPPED_ENABLE_ALL_MARKETPLACES_KEY
  )
  // Get local storage value for skipping modal
  if (skippedEnableAllMarketplacesDate) {
    const dateNow = new Date()
    const dateThen = new Date(skippedEnableAllMarketplacesDate)
    const daysPassed = Math.round(
      (dateNow.getTime() - dateThen.getTime()) / (1000 * 3600 * 24)
    )
    // If 30 days have passed, show modal
    if (daysPassed >= 30) {
      dispatch(setGlobalModal('EnableAllReviewAutomationModal'))
    } else if (isHeaderToggle) {
      dispatch(toggleReviewAutomation(!reviewAutomationEnabled))
    }
  } else {
    // If no local storage value, show modal
    dispatch(setGlobalModal('EnableAllReviewAutomationModal'))
  }
}

function getUpdatedAmazonSellerAccounts(amazonSellerAccounts, marketplaces) {
  return amazonSellerAccounts.map(account => {
    const updatedCountries = account.countries.map(country => {
      const countryIndex = marketplaces.findIndex(
        marketplace =>
          marketplace.id === country.marketplace_id &&
          marketplace.amazon_seller_account_id === account.id
      )

      if (countryIndex > -1) {
        const reviewAutomationEnabled =
          marketplaces[countryIndex].review_automation_enabled

        if (reviewAutomationEnabled) {
          safeSegmentCall(() =>
            sendSegmentTrackEvent(REVIEW_AUTOMATION_ACTIVATED)
          )
        }

        return {
          ...country,
          review_automation_enabled: reviewAutomationEnabled
        }
      }
      return country
    })

    return {
      ...account,
      countries: updatedCountries
    }
  })
}

function getUpdatedAmazonSellerAccountSelector(
  amazonSellerAccountSelector,
  updatedAmazonSellerAccounts,
  marketplaces
) {
  return {
    ...amazonSellerAccountSelector,
    selectedAccount: {
      ...amazonSellerAccountSelector.selectedAccount,
      countries: updatedAmazonSellerAccounts.find(
        account => account.id === amazonSellerAccountSelector.selectedAccount.id
      ).countries
    },
    selectedCountry: {
      ...amazonSellerAccountSelector.selectedCountry,
      review_automation_enabled: marketplaces.find(
        marketplace =>
          marketplace.id ===
          amazonSellerAccountSelector.selectedCountry.marketplace_id
      )?.review_automation_enabled
    }
  }
}

export const toggleAllReviewAutomation = enabledMarketplaces => async (
  dispatch,
  getState
) => {
  const {
    amazonSellerAccounts: { amazonSellerAccounts, amazonSellerAccountSelector }
  } = getState()

  const data = {
    marketplaces: enabledMarketplaces
  }

  dispatch({
    type: type.PENDING_REVIEW_AUTOMATION_ACTION,
    payload: 'toggle'
  })

  const res = await Api.updateAllMarketplaces(data)

  if (res.ok) {
    const { marketplaces } = res.data

    // Update the amazonSellerAccounts with updated marketplaces API response
    const updatedAmazonSellerAccounts = getUpdatedAmazonSellerAccounts(
      amazonSellerAccounts,
      marketplaces
    )

    // Also update the amazonSellerAccountSelector with the new data
    const updatedAmazonSellerAccountSelector = getUpdatedAmazonSellerAccountSelector(
      amazonSellerAccountSelector,
      updatedAmazonSellerAccounts,
      marketplaces
    )

    dispatch({
      type: SET_AMAZON_SELLER_ACCOUNTS_DATA,
      payload: {
        amazonSellerAccounts: updatedAmazonSellerAccounts,
        amazonSellerAccountSelector: updatedAmazonSellerAccountSelector
      }
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Toggle Review Automation Error'
      })
    )
  }
  dispatch({
    type: type.COMPLETED_REVIEW_AUTOMATION_ACTION,
    payload: 'toggle'
  })
}

export const updateRequestDelay = requestDelay => async (
  dispatch,
  getState
) => {
  const state = getState()
  const {
    amazon_seller_account_id,
    marketplace_id,
    country_code
  } = getSelectedAmazonSellerAccount(state)
  const {
    amazonSellerAccounts: { amazonSellerAccountSelector }
  } = state

  const data = {
    amazon_seller_account_id,
    review_request_delay: requestDelay,
    amazon_marketplace: country_code
  }

  dispatch({
    type: type.PENDING_REVIEW_AUTOMATION_ACTION,
    payload: 'request_delay'
  })

  const res = await Api.updateMarketplace(marketplace_id, data)

  dispatch({
    type: type.COMPLETED_REVIEW_AUTOMATION_ACTION,
    payload: 'request_delay'
  })

  if (res.ok) {
    dispatch({
      type: SET_AMAZON_SELLER_ACCOUNT_SELECTOR_DATA,
      payload: {
        ...amazonSellerAccountSelector,
        selectedCountry: {
          ...amazonSellerAccountSelector.selectedCountry,
          review_request_delay: requestDelay
        }
      }
    })
    safeSegmentCall(() => {
      sendSegmentTrackEvent(REVIEW_REQUEST_DELAY_UPDATED)
    })
    dispatch(
      createNotification({
        message: res.message,
        level: 'success',
        title: t(
          'review_automation:ReviewAutomationSettings.successNotification',
          'Successfully Updated Request Delay'
        )
      })
    )
    dispatch(setGlobalModal(null))
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: t(
          'review_automation:ReviewAutomationSettings.errorNotification',
          'Update Review Request Error'
        )
      })
    )
  }
}
