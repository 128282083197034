import React from 'react'
import MwsAndPpcSyncModals from '../containers/account/MwsAndPpcSyncModals'
import MwsSyncStatusbarContainer from '../containers/account/MwsSyncStatusbar'

const MwsSyncStatusbar = props => (
  <>
    <MwsAndPpcSyncModals history={props.history} />
    {props.membershipInfo && props.membershipInfo.has_launch_access && (
      <MwsSyncStatusbarContainer
        sidebarOpen={props.sidebarOpen}
        history={props.history}
      />
    )}
  </>
)

export default MwsSyncStatusbar
